<template>
  <div>
    <h2>Template Selection: <span class="font-normal">Step 2</span></h2>
    <p>Select a template to work with</p>
    <div>
      <input v-model="input" placeholder="Search for Template..." class="wizard__input w-100" />
    </div>

    <div class="mt-3">
      <p>Select your preferred template</p>

      <div>
        <b-row>
          <b-col cols="4" v-for="(template, idx) in filteredTemplates" :key="idx">
            <label :class="{
        'px-3 w-100 wizard__input mb-4': true,
        active: template.id === selected_template?.id,
      }">
              <!-- <p :style="{ fontWeight: '600' }" class="font-bold">
                {{ template.filename }} 
              </p> -->
              <div class="preset-wrapper p-3">
                <div class="template-image">
                  <img :src="template.url" alt="Template Preview" /> <!-- Use the URL from the query -->
                </div>
              </div>
              <input type="radio" name="template" :value="template" class="hidden" v-model="selected_template" />
            </label>
          </b-col>
        </b-row>
      </div>
    </div>

    <div>
      <router-link class="btn" :to="{ name: 'video-composer-wizard', query: { step: 1 } }">
        Previous
      </router-link>
      <router-link class="btn btn-primary" :to="{ name: 'video-composer-wizard', query: { step: 3 } }">
        Next
      </router-link>
    </div>

    <publish-accounts-skeleton :itemCount="12" v-if="fetching" />
    <div v-if="!fetching && templates.length === 0" class="shadow-sm text-center p-5">
      <h6 class="my-4">No templates found.</h6>
    </div>
  </div>
</template>

<script>
import { LIST_IMAGES_QUERY } from '~/graphql/queries'; // Import the new query
import PublishAccountsSkeleton from '~/components/PublishAccountsSkeleton'; // Optional loading skeleton

export default {
  components: { PublishAccountsSkeleton },

  data() {
    return {
      fetching: false,
      templates: [], // This will hold the list of images
      selected_template: null,
      input: '', // Search input
    };
  },

  computed: {
    filteredTemplates() {
      return this.templates.filter(template =>
        template.filename.toLowerCase().includes(this.input.toLowerCase()) // Filter by filename
      );
    },
  },

  created() {
    this.fetching = true;
    this.$apollo
      .query({
        query: LIST_IMAGES_QUERY, // Use the new query to fetch images
      })
      .then(({ data }) => {
        this.templates = data.listImages; // Adjusted based on the response structure
        this.fetching = false;
      })
      .catch(() => {
        this.fetching = false;
      });
  },

  methods: {
    toggleAccountSelection(template) {
      this.$emit('sync', {
        template,
        // Additional parameters if needed
      });
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

<style lang="scss">
@import '~@/scss/variables';

.hidden {
  display: none;
}

.template-image {
  width: 100%;
  height: 100px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.wizard {
  &__grid_3 {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-row: 1fr;
    max-height: 400px;
    overflow-y: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__grid_item {
    height: 150px;
    border-radius: 4px;
    background-color: rgba($color: $primary, $alpha: 1);
    border: 1px solid $primary;
    padding: 10px;
    color: white;
    margin-bottom: 0;
    transition: all 0.3s;

    &.active {
      border: 5px solid black;
      background: $secondary;
      color: $primary !important;
    }

    >img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
