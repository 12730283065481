<template>
  <div>
    <h2><b>Step 3:</b> Generate Video Script</h2>
    <p>You can choose to generate your script with AI or by your self</p>
    <main class="wizard__container">
      <div class="wizard__firstContainer">
        <div class="wizard__selection mb-3">
          <label :class="['btn', scriptType === 'ai' && 'active']">
            Generate With AI
            <input type="radio" value="ai" v-model="scriptType" name="scripttype" />
          </label>
          <label :class="['btn', scriptType === 'manual' && 'active']">
            Create Your script
            <input type="radio" value="manual" v-model="scriptType" name="scripttype" />
          </label>
        </div>

        <div v-if="scriptType === 'ai'" class="wizard__ai_scriptor mb-3">
          <div class="flex flex-row items-center justify-content-between mb-3">
            <h4 class="mb-0">AI Script Generator</h4>
            <button
              @click="generateScriptsWithAi"
              :disabled="$store.state.videoGenerator.isGenerating"
              class="btn flex items-center py-1 btn-secondary"
            >
              <div
                class="spinner-border text-primary"
                style="width: 1rem; height: 1rem;"
                v-if="$store.state.videoGenerator.isGenerating"
                role="status"
              ></div>
              Generate
            </button>
          </div>
          <textarea
            v-model="prompt"
            rows="4"
            placeholder="Please enter the description of the type of script you want"
            class="wizard__input rounded w-100"
          ></textarea>
        </div>

        <div class="wizard__scripts">
          <div
            :key="idx"
            v-for="(_, idx) in scripts"
            @dragstart="dragStart(idx)"
            @dragover.prevent
            @drop="drop(idx)"
            :draggable="scripts.length > 2"
            class="wizard__entry"
          >
            <div class=" w-100">
              <div class="px-4 pt-3 w-100">
                <!-- remove description and replace with display text.... the scene should take the rextarea -->
                <input
                  class="mb-2 w-100 px-2 form-control py-0 rounded"
                  v-model="scripts[idx].displaytext"
                  placeholder="Enter description..."
                />
                <textarea
                  v-model="scripts[idx].scene"
                  placeholder="Enter Script"
                  rows="3"
                  class="mb-0 py-0 form-control px-2 w-100 rounded"
                ></textarea>
              </div>
              <div class="flex px-4 flex-row py-2 items-center justify-content-between">
                <div :class="{ hidden: !(scripts.length !== idx + 1) }">
                  <button class="btn btn-info btn-sm mr-2" v-show="scripts.length > 2">
                    <i class="bi bi-arrows-move"></i> Move
                  </button>
                  <button @click="() => deleteScriptAt(idx)" class="btn btn-danger btn-sm">
                    <i class="bi bi-x-lg"></i> Delete
                  </button>
                </div>

                <div :class="{ hidden: scripts.length !== idx + 1, 'mb-2': true }">
                  <button @click="scripts.push({})" class="btn btn-sm btn-success">
                    <i class="bi bi-plus-lg"></i> Add More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-button
          v-show="scripts.length > 0"
          @click="saveVideoGeneration"
          :disabled="loading"
          primary
          class="btn btn-primary"
        >
          <b-spinner v-if="loading" sm label="Loading..."></b-spinner>
          Proceed to editor
        </b-button>
        <router-link class="btn " :to="{ name: 'video-composer-wizard', query: { step: 2 } }">
          Previous
        </router-link>
      </div>
      <div class="wizard__promptContainer">
        <h2>DFY Prompts</h2>
        <p>
          This ready-made prompt is a perfect start! Customize it to fit your brand and create a professional video
          script.
        </p>
        <b-button @click="isShowPrompt = !isShowPrompt">
          Select Prompt
        </b-button>
        <transition name="fade">
          <div
            v-if="isShowPrompt"
            class="bg-white shadow-lg rounded-md mt-3 scrollable"
            style="min-height: 100px; max-height: 200px; overflow-y: auto; padding: 20px"
          >
            <div v-for="(post, id) in dfyPrompt" :key="id">
              <h5>{{ post.category }}</h5>
              <div>
                <p v-for="(promptPost, idx) in post.prompts" :key="idx" class=" text-justify">
                  {{ promptPost }}
                  <br />
                  <b-button
                    class="btn bg-success mt-2 text-white"
                    @click="copyPromptSample(promptPost, idx)"
                    :disabled="isCopied[idx]"
                    >{{ isCopied[idx] ? 'Copied' : 'Copy Prompt' }}
                  </b-button>
                  <b-button class="btn btn-primary mt-2 ml-2 " @click="usePrompt(promptPost)">
                    Use Prompt
                  </b-button>
                </p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: 'VCStep3',
  data: () => ({
    prompt: undefined,
    dataKey: undefined,
    scriptType: 'manual',
    draggedItemIndex: null,
    isCopied: [],
    isShowPrompt: false,
    dfyPrompt: [
      {
        category: 'Marketing Video',
        prompts: [
          'Create a marketing video to introduce [Product/Service Name]. Focus on how it solves [specific customer problem] and include [key product benefits].',
          'Generate a script that promotes [Product/Service Name] for [Target Audience] by highlighting [unique selling points]. End with a call to action encouraging viewers to [desired action].',
          'Develop a video to showcase the [seasonal promotion/discount] we are offering on [Product/Service Name]. Highlight the limited-time nature and the value customers get.',
        ],
      },
      {
        category: 'Promotional Video',
        prompts: [
          'Create a short promotional video for [upcoming event, product launch, etc.] that highlights [event details, features, special offers]. Focus on generating excitement and urgency.',
          'Write a script for a promotion video about our new product, [Product Name], emphasizing how it outperforms competitors and why customers should choose us now.',
          'Develop a promotional video script for our [holiday campaign or sale]. Mention the discounts and how customers can take advantage before [deadline].',
        ],
      },
      {
        category: 'Educational/How-to Video',
        prompts: [
          'Generate a step-by-step educational video script to teach users how to use [Product/Service]. Include easy-to-follow instructions and mention how to troubleshoot common issues.',
          'Create a script for a video that explains [specific process, e.g., how to improve marketing with our platform] and why it’s important for [Target Audience].',
          'Write a script for an instructional video that demonstrates the best practices for using [Product Name] in a way that helps [Target Audience] achieve [specific result].',
        ],
      },
      {
        category: 'Feature Showcase',
        prompts: [
          'Develop a script to highlight the top [number] features of [Product/Service Name], emphasizing how each feature benefits [Target Audience].',
          'Create a feature showcase video to explain the latest updates in [Product/Service Name] and how these improvements make it easier for customers to [specific action].',
          "Write a video script to demonstrate how [Product Feature] works in real-world scenarios, showing the impact it has on [Target Audience's business goals].",
        ],
      },
      {
        category: 'Testimonial Video',
        prompts: [
          'Generate a video script based on customer testimonials for [Product/Service Name]. Focus on [specific pain points] the customers mention and how your product solved them.',
          'Write a script for a testimonial video where [Customer/Industry] shares their experience with [Product/Service] and highlights the benefits they’ve seen.',
        ],
      },
      {
        category: 'Explainer Video',
        prompts: [
          'Create a script for an explainer video about [Product/Service Name], detailing how it works, who it’s for, and the key problems it solves.',
          "Write a video script that explains [Company Name]'s mission and how our solutions help businesses achieve [specific goal]. Include examples of real-world use cases.",
        ],
      },
      {
        category: 'Brand Story Video',
        prompts: [
          'Create a script for a brand story video that explains how [Company Name] started, its mission, and the impact it has had on [Target Audience/Industry].',
          'Develop a script for a video that tells the story behind [Product/Service Name], why it was created, and how it aligns with [company values].',
        ],
      },
      {
        category: 'Product Comparison Video',
        prompts: [
          'Generate a script comparing [Product Name] with [Competitor Product]. Focus on the key differences, benefits, and why customers should choose us.',
          'Write a video script that contrasts the features of [Product/Service] against other options in the market and highlight what makes ours stand out.',
        ],
      },
      {
        category: 'Customer Journey Video',
        prompts: [
          'Create a script that follows the journey of a customer using [Product/Service Name], from discovery to problem-solving and the success they experience afterward.',
          'Develop a script that narrates a typical experience for [Target Audience] using [Product/Service], focusing on their pain points and how our solution addresses them.',
        ],
      },
      {
        category: 'Social Media Video',
        prompts: [
          'Generate a short, engaging video script for [social media platform] to promote [Product/Service Name] with a fun and light-hearted tone. Include a catchy call to action.',
          'Write a script for a viral social media challenge using [Product/Service Name]. Include instructions for participation and encourage users to share their experience.',
        ],
      },
    ],
  }),

  watch: {
    scriptType: {
      immediate: true,
      handler() {
        if (this.scriptType === 'manual') {
          this.scripts = [{ displaytext: '', description: '', voiceover: '' }];
        } else {
          this.scripts = [];
        }
      },
    },
  },
  computed: {
    scripts: {
      get() {
        return this.$store.state.videoGenerator.scripts;
      },
      set(value) {
        this.$store.state.videoGenerator.scripts = value;
      },
    },
    workspace: {
      get() {
        return this.$store.state.workspace.current;
      },
      set(value) {
        this.$store.state.workspace.current = value;
      },
    },

    loading: {
      get() {
        return this.$store.state.videoGenerator.generatingScripts;
      },
      set(value) {
        this.$store.state.videoGenerator.generatingScripts = value;
      },
    },

    generatingScripts() {
      return this.$store.state.videoGenerator.isGenerating;
    },
  },
  methods: {
    async generateScriptsWithAi() {
      try {
        if (!this.prompt) return;
        await this.$store.dispatch('videoGenerator/generateScripts', {
          message: this.prompt,
          workspace_id: this.workspace.id,
          count: 10,
        });
      } catch (error) {
        alert(error);
      }
    },
    moveToEditor(key) {
      //TODO Design the new editor page
      // const VIDEO_EDITOR_URL =
      //   window.location.protocol === 'http:' ? 'https://videditor.test' : 'https://video.socialagency360.com';
      // const viewlist = window.location.href.replace(
      //   'video-composer-editor', //
      //   'video-composer-list',
      // );
      // const navigateTo = new URL(VIDEO_EDITOR_URL);
      // navigateTo.searchParams.append('key', key);
      // navigateTo.searchParams.append('to', viewlist);
      // navigateTo.searchParams.append('from', window.location.href);
      // navigateTo.searchParams.append('baseurl', process.env.VUE_APP_API_ROOT);
      // window.location.href = navigateTo.toString();
    },
    async saveVideoGeneration() {
      try {
        this.loading = true;
        const videoId = await this.$store.dispatch('videoGenerator/proceedToEditor');
        if (videoId) this.moveToEditor(videoId);
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },

    deleteScriptAt(idx) {
      this.scripts.splice(idx, 1);
    },
    dragStart(index) {
      this.draggedItemIndex = index;
    },
    drop(index) {
      const draggedItem = this.scripts[this.draggedItemIndex];
      this.scripts.splice(this.draggedItemIndex, 1);
      this.scripts.splice(index, 0, draggedItem);
      this.draggedItemIndex = null;
    },

    copyPromptSample(prompt, idx) {
      navigator.clipboard
        .writeText(prompt)
        .then(() => {
          this.$set(this.isCopied, idx, true);
          setTimeout(() => {
            this.$set(this.isCopied, idx, false);
            this.isShowPrompt = false;
          }, 2000);
        })
        .catch(err => {
          console.error('Failed to copy prompt: ', err);
        });
    },

    usePrompt(prmt) {
      if (this.scriptType === 'manual') {
        this.scripts[0].displaytext = prmt;
        setTimeout(() => {
          this.isShowPrompt = false;
        }, 2000);
        return;
      }
      this.prompt = prmt;
      setTimeout(() => {
        this.isShowPrompt = false;
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/scss/variables';

$checkbox_width: 20px;
$border-radius: 5px;
p {
  font-weight: 400;
}
.wizard {
  &__ai_scriptor {
    padding: 15px;
    background-color: $primary;
    border-radius: $border-radius;
    // color: white;

    h4 {
      font-size: 20px;
      color: white;
    }

    textarea {
      color: black;
      &::placeholder {
        color: rgba(245, 245, 245, 0.678);
      }
    }
  }
  &__container {
    display: flex;
    gap: 2rem;
  }
  &__firstContainer {
    max-width: 60rem;
    // margin: auto;

    width: 100%;
  }
  &__promptContainer {
    width: 40rem;
  }
  &__scripts {
    margin-bottom: 15px;
    border-radius: $border-radius;
  }
  &__entry {
    display: flex;
    flex-direction: row;
    background: rgba(0, 128, 58, 0.185);
    border: 1px solid $primary;
    align-items: center;
    border-radius: $border-radius;
    margin-bottom: 10px;
  }
  &__selection {
    display: flex;
    gap: 10px;
    label {
      width: 100%;
      background-color: rgba(0, 128, 58, 0.185);
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: 30px 10px;
      border: 1px solid;

      &.active {
        border: 4px solid;
      }

      > input[type='radio'] {
        display: none;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.scrollable::-webkit-scrollbar {
  width: 15px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #1f6948;
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}
</style>
