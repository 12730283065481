<template>
  <div class="wizard__container">
    <div :style="{ textAlign: 'center', marginTop: '20px' }">
      <h2 class="mb-1 font-bold">Video Creator Wizard</h2>
      <p>Follow the steps to create you videos in seconds</p>
    </div>
    <div :style="{ textAlign: 'center' }">
      <div class="d-inline-block btn-group">
        <router-link :to="prevStepRoute" :style="{ fontSize: '12px' }" class="btn mr-2 btn-outline-primary">
          <i class="bi bi-arrow-left-circle"></i>
          Previous
        </router-link>
        <router-link
          class="btn bg-[#95d5b2] !text-sm py-2 px-3"
          :style="{ fontWeight: 'bold' }"
          :class="{ 'bg-primary text-light': isActive(idx + 1, idx === 0) }"
          v-for="(step, idx) in steps"
          :to="step.to"
          :key="idx"
        >
          {{ step.label }}
        </router-link>
        <router-link :to="nextStepRoute" :style="{ fontSize: '12px' }" class="btn ml-2 btn-outline-primary px-4">
          Next
          <i class="bi bi-arrow-right-circle"></i>
        </router-link>
      </div>
    </div>
    <section class="mb-5 py-5">
      <article class="pb-5">
        <Step1 v-if="isActive(1, true)" />
        <Step2 v-if="isActive(2)" />
        <Step3 v-if="isActive(3)" />
      </article>
    </section>
  </div>
</template>
<script>
import Step1 from '@/components/VideoComposer/Step1.vue';
import Step2 from '@/components/VideoComposer/Step2.vue';
import Step3 from '@/components/VideoComposer/Step3.vue';

export default {
  name: 'VideoComposerWizard',
  components: {
    Step1,
    Step2,
    Step3,
  },
  data: () => ({
    form: {
      title: '',
      background: {},
    },
  }),
  computed: {
    currentStep() {
      return +this.$route.query?.step || 0;
    },
    prevStepRoute() {
      return this.currentStep > 0
        ? { name: 'video-composer-wizard', query: { step: this.currentStep - 1 } }
        : { name: 'video-composer-wizard', query: { step: 0 } };
    },
    nextStepRoute() {
      return this.currentStep < 3
        ? { name: 'video-composer-wizard', query: { step: this.currentStep + 1 } }
        : { name: 'video-composer-wizard', query: { step: 3 } };
    },
    steps() {
      return [
        {
          label: 'Step One',
          to: { name: 'video-composer-wizard', query: { step: 1 } },
        },
        {
          label: 'Step Two',
          to: { name: 'video-composer-wizard', query: { step: 2 } },
        },
        {
          label: 'Step Three',
          to: { name: 'video-composer-wizard', query: { step: 3 } },
        },
      ];
    },
  },
  methods: {
    isActive(tab, isDefault = false) {
      if (isDefault) return +this.$route.query?.step === tab || !this.$route.query.step;
      return +this.$route.query?.step === tab;
    },
  },
};
</script>
<style lang="scss">
@import '~@/scss/variables';

.wizard {
  &__input {
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid $primary;
    transition: all 0.3s;
  }
  &__container {
    padding-top: 0px;
    min-height: 100vh;
    width: 100%;
    top: 0px;

    p {
      font-weight: 400;
    }

    > section {
      height: 100%;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      // align-items: center;
      // border: 2px solid red;

      > article {
        max-width: 70rem;
        // margin: auto;
        background-color: white;
        padding: 40px;

        width: 100%;
      }
    }
  }
}
</style>
